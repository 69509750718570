<template>
  <div class="container system-check">
    <div class="row mt-5">
      <div class="col-md-10 mx-auto">
        <div class="card border-0 shadow-none">
          <div class="card-body p-5">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <p class="lead">System Check</p>
                    <p class="mb-4">{{ preflightDescription }}</p>
                  </div>
                </div>

                <div v-if="!testPassed && !testInprogress" class="row mb-4">
                  <div class="col text-center">
                    <button
                      v-if="browserSupported"
                      type="button"
                      class="btn btn-primary"
                      data-toggle="button"
                      aria-pressed="false"
                      autocomplete="off"
                      @click="restartTest"
                    >
                      Retry test
                    </button>
                    <div v-else>
                      <p class="lead mt-3">
                        Your browser is not supported.<br/>
                        {{ unsupportedBrowserMessage }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="video-container card">
                      <video ref="localVideo" class="local-video card-img-top" muted playsinline autoplay></video>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="video-container card">
                      <video ref="remoteVideo" class="remote-video card-img-top" muted playsinline autoplay></video>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <select class="form-control" @change="deviceChanged('video')">
                        <option
                          v-for="(device, index) in camerasList"
                          :value="device['deviceId']"
                          :key="index"
                          :selected="device['deviceId'] == selectedCamera"
                        >
                          {{ device['label'] }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control" @change="deviceChanged('audio')">
                        <option
                          v-for="(device, index) in microphonesList"
                          :value="device['deviceId']"
                          :key="index"
                          :selected="device['deviceId'] == selectedMicrophone"
                        >
                          {{ device['label'] }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col text-left">
                    <div class="alert" :class="browserSupported ? 'alert-success' : 'alert-danger'" role="alert">
                      <p>Browser {{ browserSupportedText }}</p>
                    </div>
                    <div v-if="localStream" class="alert" :class="soundProblem ? 'alert-warning' : 'alert-success'" role="alert">
                      <p>
                        Local stream received
                        <template v-if="soundProblem">
                          - {{ soundProblem }}
                        </template>
                      </p>
                    </div>
                    <div v-if="gotRemoteStream" class="alert alert-success" role="alert">
                      <p>Remote stream received</p>
                    </div>
                    <div v-if="showPacketsWarning" class="alert alert-warning" role="alert">
                      <p>Slow connection</p>
                    </div>
                    <div v-if="socketBlocked" class="alert alert-danger" role="alert">
                      <p>Can't establish connection to the signaling server. Please contact us.</p>
                    </div>
                    <div v-if="deviceProblem" class="alert alert-danger" role="alert">
                      <template v-if="needUpdatePermission">
                        <p>{{ deviceProblemText }}. To use this portal please follow these steps and allow access to these devices:</p>
                        <ol class="ml-3">
                          <li v-for="(instruction, index) in permissionsInstructions" :key="index" v-html="instruction"></li>
                        </ol>
                      </template>
                      <template v-else>
                        <p>{{ deviceProblemText }}</p>
                      </template>
                    </div>
                    <div v-if="networkProblem" class="alert alert-danger" role="alert">
                      <p>{{ networkProblemText }}</p>
                    </div>
                    <div class="alert" :class="testClasses" role="alert">
                      <p>Test {{ testPassedText }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as io from "socket.io-client";
import { MicTest } from "./MicTest.js";

export default {
  props: {
    success: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      browserSupported: false,
      browserSupportedText: 'checking',
      deviceProblem: false,
      deviceProblemText: false,
      camerasList: [],
      candidates: [],
      gotRemoteStream: false,
      isAndroid: false,
      iceCandidateTimeout: null,
      isChrome: false,
      isChromeOnIOS: false,
      isIOS: false,
      isSafari: false,
      iOSversion: {'major': null, 'minor': null},
      localStream: null,
      microphonesList: [],
      needUpdatePermission: false,
      networkProblem: false,
      networkProblemText: '',
      packetsLostThreshold: 25,
      pc1: null,
      pc2: null,
      remoteStream: null,
      selectedCamera: null,
      selectedMicrophone: null,
      showPacketsWarning: false,
      socket: null,
      socketBlocked: false,
      socketConnected: false,
      soundProblem: '',
      supportedIOSVersion: {major: 14, minor: 3},
      testInprogress: true,
      testPassed: '',
      testPassedText: 'in progress',
      timers: {},
    };
  },
  computed: {
    iOSSupported() {
      let iOSSupported = true;

      for (let key in this.supportedIOSVersion) {
        if (this.iOSversion[key] < this.supportedIOSVersion[key]) {
          iOSSupported = false;
        }
      }

      return iOSSupported;
    },
    permissionsInstructions() {
      let instructions = [];
      let ua = navigator.userAgent;

      if (this.isIOS) {
        if (this.isChromeOnIOS) {
          instructions.push(`Use these ${this.getSupportLink('iOS')}.`);
        } else if (this.iOSversion) {
          instructions.push(`Try refreshing the page - the permissions pop-up should appear again.`);
          instructions.push(`If the pop-up did not appear go to Settings on your device.`);
          instructions.push(`Scroll down and open the Safari tab.`);
          instructions.push(`Ensure your Camera and Microphone are set to Allow.`);
          instructions.push(`Make sure the Request Desktop Site option is turned off.`);
        }
      } else if (this.isChrome) {
        if (this.isAndroid) {
          instructions.push(`Use these ${this.getSupportLink('Android')}.`);
        } else {
          instructions.push(`Use these ${this.getSupportLink('Desktop')}.`);
        }
      } else if (this.isSafari) {
        instructions.push(`Try refreshing the page - the permissions pop-up should appear again.`);
        instructions.push(`If the pop-up did not appear, on the top bar click on Safari -> Preferences.`);
        instructions.push(`In the preferences window, navigate to the Website section, find Camera, click on this site's name selector and choose Allow.`);
        instructions.push(`Repeat the same for Microphone.`);
      } else {
        instructions.push(`Try refreshing the page - the permissions pop-up should appear again.`);
        instructions.push(`If the pop-up did not appear open browser settings.`);
        instructions.push(`Go to Privacy and security.`);
        instructions.push(`If you see Camera or Microphone click Settings next to them.`);
        instructions.push(`Otherwise click Site settings and then click Camera or Microphone.`);
        instructions.push(`Delete or allow existing exception or permission for this site.`);
      }

      instructions.push('Reload the page and retry the test.');

      return instructions;
    },
    preflightDescription() {
      let text = 'Test takes no more than a minute';
      if (this.success) {
        text += ', if it\'s alright you will be redirected to the consultation.';
      } else {
        text += '.';
      }

      return text;
    },
    testClasses() {
      let testClass;

      if (this.testInprogress) {
        testClass = 'alert-warning';
      } else if (this.testPassed) {
        testClass = 'alert-success';
      } else {
        testClass = 'alert-danger';
      }

      return testClass;
    },
    unsupportedBrowserMessage() {
      let text;

      if (this.isIOS) {
        if (this.iOSSupported) {
          text = 'Please use the latest version of one of this: Safari, Chrome.';
        } else {
          text = `Please update your iOS at least to ${this.supportedIOSVersion['major']}.${this.supportedIOSVersion['minor']}.`;
        }
      } else {
        text = 'Please use the latest version of one of this: Chrome, Firefox.';
      }

      return text;
    },
  },
  methods: {
    addEventsListener(pc) {
      let otherPc = this.getOtherPc(pc);

      pc.onicecandidate = async ({candidate}) => {
        this.candidates.push(candidate);
        if (otherPc.signalingState !== 'closed') {
          await otherPc.addIceCandidate(candidate);
        }
      };

      pc.oniceconnectionstatechange = ev => {
        if (pc.iceConnectionState == 'completed') {
          this.onSuccessedTest();
        } else if (pc.iceConnectionState == 'disconnected' && !this.testPassed) {
          this.resetConncetions(pc, otherPc);
          this.processNetworkError();
        }
      }

      pc.onconnectionstatechange = ev => {
        if (pc.iceConnectionState == 'connected') {
          this.onSuccessedTest();
        }
      }

      pc.onicegatheringstatechange = () => {
        clearTimeout(this.iceCandidateTimeout);

        if (pc.iceGatheringState == 'gathering') {
          this.iceCandidateTimeout = setTimeout(() => {
            this.resetConncetions(pc, otherPc);
            this.processNetworkError();
          }, 60000);
        }

        if (pc.iceGatheringState == 'complete' && !this.candidates.length) {
          this.resetConncetions(pc, otherPc);
          this.processNetworkError();
        }
      }

      pc.onnegotiationneeded = async () => {
        try {
          await pc.setLocalDescription(await pc.createOffer());
          otherPc.setRemoteDescription(pc.localDescription);

          this.localStream.getTracks().forEach((track) => {
            otherPc.addTrack(track, this.localStream);
          });
          await otherPc.setLocalDescription(await otherPc.createAnswer());
          pc.setRemoteDescription(otherPc.localDescription);
        } catch (err) {
          console.error(err);
        }
      };

      pc.ontrack = (event) => {
        if (pc === this.pc2) {
          this.remoteStream = event.streams[0];
          this.$refs.remoteVideo.srcObject = this.remoteStream;
        }
      };
    },
    checkBrowser() {
      let RTCPeerConnectionSupported = !!window.RTCPeerConnection;
      let getUserMediaSupported = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
      let browserSupportsWebrtc = RTCPeerConnectionSupported && getUserMediaSupported;
      let canUseBrowser = false;

      let ua = navigator.userAgent;

      let hasVersion = /Version\/(\d+)/i.test(ua);
      let hasChrome = /Chrome\/(\d+)/i.test(ua);
      let hasMobileChrome = /CriOS\/(\d+)/i.test(ua);
      let hasSafari = /Safari\/(\d+)/i.test(ua);
      let isAppleDevice = /Mac OS/i.test(ua);

      this.isAndroid = /android/i.test(ua);
      this.isChromeOnIOS = hasMobileChrome;
      this.isIOS = /iP(hone|od|ad)/i.test(ua) || hasMobileChrome;
      this.isSafari = isAppleDevice && hasSafari && hasVersion && !hasMobileChrome;
      this.isChrome = hasChrome && !/\b(OPR|Edge)/.test(ua);

      if (this.isIOS) {
        let iOSVersion = ua.match(/OS(?:\sX)? ((?:\d+_?)+)/i);

        if (iOSVersion) {
          iOSVersion = iOSVersion[1].split('_');

          this.iOSversion = {
            major: iOSVersion[0],
            minor: iOSVersion[1]
          };
        }

        if (this.iOSSupported && (hasVersion || hasMobileChrome)) {
          canUseBrowser = true;
        }
      } else {
        canUseBrowser = true;
      }

      return browserSupportsWebrtc && canUseBrowser;
    },
    deviceChanged(type) {
      switch (type) {
        case 'video':
          this.selectedCamera = event.target.value;
          break;

        case 'audio':
          this.selectedMicrophone = event.target.value;
          break;
      }

      this.restartTest();
    },
    monitorPcStats() {
      let lastShown;
      let showInterval = 30000;
      let lastInfo = {
        audio: {
          packetsLost: 0,
          packetsReceived: 0,
          packetsLostPercentage: 0,
        },
        video: {
          packetsLost: 0,
          packetsReceived: 0,
          packetsLostPercentage: 0,
        },
      };

      let getPcStats = () => {
        this.pc1.getStats(null)
          .then(stats => {
            let time = Date.now();

            stats.forEach(report => {
              if (report.type === 'inbound-rtp' && ['audio', 'video'].includes(report.kind)) {
                let packetsLost = report.packetsLost - lastInfo[report.kind]['packetsLost'];
                let packetsReceived = report.packetsReceived - lastInfo[report.kind]['packetsReceived'];

                if (!packetsLost) {
                  packetsLost = report.packetsLost;
                  packetsReceived = report.packetsReceived;
                 }

                lastInfo[report.kind]['packetsLostPercentage'] = Math.floor((packetsLost / (packetsReceived + packetsLost)) * 100);
                lastInfo[report.kind]['packetsLost'] = report.packetsLost;
                lastInfo[report.kind]['packetsReceived'] = report.packetsReceived;
              }
            });

            let audioPacketsLostPercentage = lastInfo['audio']['packetsLostPercentage'];
            let videoPacketsLostPercentage = lastInfo['video']['packetsLostPercentage'];

            if (
              audioPacketsLostPercentage >= this.packetsLostThreshold ||
              videoPacketsLostPercentage >= this.packetsLostThreshold
            ) {
              this.showPacketsWarning = true;
            } else {
              this.showPacketsWarning = false;
            }

            this.timers['packets'] = setTimeout(getPcStats, 1000);
          });
      };

      getPcStats();
    },
    getOtherPc(pc) {
      return (pc === this.pc1) ? this.pc2 : this.pc1;
    },
    getSupportLink(platform) {
      let link;
      let linkTemplate = `<a href="%address" class="instruction-link" target="_blank">instructions</a>`;
      let chromeSupportPageLink = 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3D%platform&oco=1';
      let address;

      address = chromeSupportPageLink.replace('%platform', platform);
      link = linkTemplate.replace('%address', address)

      return link;
    },
    onFailedsTest() {
      ['socket', 'nacks'].forEach((timer) => {
        clearTimeout(this.timers[timer]);
      })
      this.testInprogress = false;
      this.testPassed = false;
      this.testPassedText = 'failed';
    },
    onSuccessCallback() {
      if (typeof this.success == 'function') {
        this.success(this.localStream);
      }
    },
    onSuccessedTest() {
      if (!this.socketConnected && !this.socketBlocked) {
        this.timers['socket'] = setTimeout(() => {
          this.onSuccessedTest()
        }, 100);
      } else if (this.socketConnected && !this.testPassed) {
        this.testInprogress = false;
        this.testPassed = true;
        this.testPassedText = 'passed';

        setTimeout(()=> {this.onSuccessCallback();}, 1000);
      }
    },
    processDeviceError(error) {
      if (error.name == 'NotFoundError') {
        this.deviceProblemText = 'Camera and/or microphone not found in system';
      } else if (error.name == 'NotReadableError') {
        this.deviceProblemText = `Can't access camera or microphone. Retry test. If it not helps try to turn off your browser or device then turn it on and try again.`;
      } else if (error.name == 'NotAllowedError') {
        this.needUpdatePermission = true;
        this.deviceProblemText = `You denied access to the camera and/or microphone`;
      } else {
        this.deviceProblemText = error.message;
      }

      this.onFailedsTest();
    },
    processNetworkError() {
      this.networkProblem = true;
      this.networkProblemText = `Can't establish connection to TURN server. Please contact us.`;
      this.onFailedsTest();
    },
    resetConncetions(pc, otherPc) {
      clearTimeout(this.iceCandidateTimeout);
      ['socket', 'packets'].forEach((timer) => {
        clearTimeout(this.timers[timer]);
      })

      pc.close();
      pc = null;

      otherPc.close();
      otherPc = null;
    },
    resetTest() {
      ['socket', 'packets'].forEach((timer) => {
        clearTimeout(this.timers[timer]);
      })

      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }

      if (this.localStream) {
        this.localStream.getTracks().forEach(track => {track.stop();})
      }

      if (this.remoteStream) {
        this.remoteStream.getTracks().forEach(track => {track.stop();})
      }

      if (this.pc1) {
        this.pc1.close();
        this.pc1 = null;
      }

      if (this.pc2) {
        this.pc2.close();
        this.pc2 = null;
      }

      this.soundProblem = '';

      if (this.micTest) {
        this.micTest.stop();
      }

      this.$refs.localVideo.srcObject = null;
      this.$refs.remoteVideo.srcObject = null;

      this.candidates = [];
      this.deviceProblem = false;
      this.deviceProblemText = false;
      this.gotRemoteStream = false;
      this.localStream = null;
      this.needUpdatePermission = false;
      this.networkProblem = false;
      this.networkProblemText = '';
      this.remoteStream = null;
      this.showPacketsWarning = false;
      this.socketBlocked = false;
      this.socketConnected = false;
      this.testInprogress = true;
      this.testPassed = '';
      this.testPassedText = 'in progress';
    },
    restartTest() {
      this.resetTest();
      this.startTest();
    },
    async getDevices() {
      let devices  = [];
      let result = false;

      devices = await navigator.mediaDevices.enumerateDevices();

      this.camerasList = [];
      this.microphonesList = [];

      devices.forEach((device) => {
        if (device.kind == 'videoinput') {
          if (!this.selectedCamera) {
            this.selectedCamera = device['deviceId'];
          }

          this.camerasList.push(device);
        }

        if (device.kind == 'audioinput') {
          if (!this.selectedMicrophone) {
            this.selectedMicrophone = device['deviceId'];
          }

          this.microphonesList.push(device);
        }
      });

      result = true;

      return result;
    },
    async startTest() {
      try {
        let id = Date.now().toString().slice(-9);

        this.socket = io('https://turn.swandoola.com:8080', {
          transports: ['websocket'],
          forceNew: true,
          reconnection: false,
          query: {
            userId: id,
            userToken: `user_token_${id}`,
            dropPreviousConnection: false,
          }
        });

        this.socket.on('connect', () => {
          this.socketConnected = true;
        });

        this.socket.on('connect_error', (error) => {
          this.socketBlocked = true;
          this.onFailedsTest();
        });

        let options = {audio: true, video: true};

        if (this.selectedCamera && this.selectedMicrophone) {
          options = {
            audio: {deviceId: this.selectedMicrophone},
            video: {deviceId: this.selectedCamera},
          };
        }

        this.localStream = await navigator.mediaDevices.getUserMedia(options);
        this.micTest = new MicTest(this.localStream, (result) => {
          if (result) {
            this.soundProblem = '';
          } else {
            this.soundProblem = 'no sound detected, please check your microphone';
          }
        });
        this.micTest.start();

        this.$refs.localVideo.srcObject = this.localStream;

        await this.getDevices();

        const configuration = {
          iceServers: [
            {urls: 'stun:stun.l.google.com:19302'},
            {
              urls: "turn:35.189.104.189:443?transport=udp",
              username: "user",
              credential: "7VyQFJ2k98KaJ79esWHU"
            },
            {
              urls: "turn:35.189.104.189:443?transport=tcp",
              username: "user",
              credential: "7VyQFJ2k98KaJ79esWHU"
            },
          ],
          iceTransportPolicy: "relay"
        };

        this.pc1 = new RTCPeerConnection(configuration);
        this.pc2 = new RTCPeerConnection(configuration);

        [this.pc1, this.pc2].forEach(pc => this.addEventsListener(pc));

        this.timers['packets'] = this.monitorPcStats();

        this.localStream.getTracks().forEach((track) => {
          this.pc1.addTrack(track, this.localStream);
        });
      } catch (e) {
        this.deviceProblem = true;

        this.processDeviceError(e);
      }
    },
  },
  mounted() {
    this.$refs.remoteVideo.onloadedmetadata = (event) => {
      this.gotRemoteStream = true;
    };
    if (this.checkBrowser()) {
      this.browserSupported = true;
      this.browserSupportedText = 'supported';
      this.startTest();
    } else {
      this.browserSupported = false;
      this.browserSupportedText = 'unsupported';
      this.onFailedsTest();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timers['packets']);

    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }

    if (this.pc1) {
      this.pc1.close();
      this.pc1 = null;
    }

    if (this.pc2) {
      this.pc2.close();
      this.pc2 = null;
    }

    if (this.micTest) {
      this.micTest.stop();
    }
  },
};
</script>

<style lang="scss">
.system-check .video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 1rem;
}
.system-check .video-container video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #bbb;
  object-fit: cover;
}
.system-check .instruction-link {
  text-decoration: underline;
}
</style>
